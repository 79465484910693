import React from "react"
import { Link } from "gatsby"

import Layout from "../../../components/layout";
const imageBaconBao = '/tysonscore3.azureedge.net/assets/media/wrightbrand/images/recipes/wrightbrand-bacon-bao-recipe-1367x737px.jpg';

const RecipePage = () => (
    <Layout metaTitle="Steamed Bao Buns with Bacon Recipe"
        metaDescription="Master the art of making Bacon Bao with
        our easy-to-follow recipe. Experience the
        taste of authentic Chinese steamed buns
        filled with flavorful bacon at home!"
        metaKeywords=""
    >
        <meta property="og:image" content={imageBaconBao} />
        <link rel="image_src" href={imageBaconBao} />
        <div id="recipe-details-wrapper" >
            <div class="image">
                <img src={imageBaconBao} class='img-responsive' alt="Bacon Bao" />
            </div>

            <div class="inner-wrapper">
                <div id="recipe-details">
                    <div class="container">
                        <div class="content-wrapper">
                            <h1>Bacon Bao</h1>

                            <div class="recipe-content">
                                <p >It's bao meets our thick-cut bacon that's sure to wow everyone.</p>
                            </div>
                        </div>

                        <div class="timing row">
                            <div class="col-md-4 col-xs-12 time">
                                <div class="icon">
                                    <img src="/tysonscore3.azureedge.net/assets/media/prepIcon.png" alt="Prep Time" />
                                </div>
                                <div class="details">
                                    <h4>Prep Time:</h4>
                                    <p>
                                        1&nbsp;hour                            </p>
                                </div>
                            </div>

                            <div class="col-md-4 col-xs-12 time">
                                <div class="icon">
                                    <img src="/tysonscore3.azureedge.net/assets/media/cookIcon.png" alt="Cook Time" />
                                </div>
                                <div class="details">
                                    <h4>Cook Time:</h4>
                                    <p>
                                        20&nbsp;minutes                            </p>
                                </div>
                            </div>

                            <div class="col-md-4 col-xs-12 time">
                                <div class="icon">
                                    <img src="/tysonscore3.azureedge.net/assets/media/serveIcon.png" alt="Serving Size" />
                                </div>
                                <div class="details">
                                    <h4>Serving Size:</h4>
                                    <p>4 Bao</p>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>

                <div class="ingredients-wrapper recipe-section">
                    <div class="container">


                        <h2>Ingredients</h2>
                        <div class="row ingredients">
                            <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                                8 slices of Wright® Brand, Thick-Cut Bacon
                </div>
                            <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                                4 bao buns, steamed
                </div>
                            <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                                2 teaspoon hoisin sauce
                </div>
                            <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                                2 teaspoon wasabi mayo
                </div>
                            <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                                1/8 teaspoon black sesame seeds
                </div>
                            <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                                3 ounces Asian slaw
                </div>
                            <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                                2 mini cucumbers
                </div>      <div class="col-md-4 col-sm-6 col-xs-12 ingredient">
                                1 scallion bunch
                </div>
                        </div>


                    </div>
                </div>

                <div class="instructions-wrapper recipe-section">
                    <div class="container">


                        <h2>Directions</h2>
                        <div class="instructions">
                            <p>
                                1. Preheat convetional oven to 400F.

                </p>
                            <p>
                                2. Place the bacon on a foil lined sheet pan in a single layer, cook for 18 - 20 mins or until cooked to desired crispness.
                </p>
                            <p>
                                3. Remove bacon from oven & place on paper towels to drain excess grease. Cut bacon pieces in half. Set aside for later use.
                </p>
                            <p>
                                4. On a small square of parchment paper or wax paper, placed the steamed bao buns.
                </p>
                           <p>
                                5. Smear approx. ¼ tsp hoisin sauce across the bottom of the bun.
                </p>
                           <p>
                                6. Top each with ¾ oz. of an Asian inspired slaw.
                </p>
                           <p>
                                7. Apply 2 halves of bacon, stacked per bao bun.
                </p>
                           <p>
                                8. Cut mini cucumbers in thin slices. Shingle out 3 cucumber slices, drizzle with wasabi mayo and garnish with black sesame seeds & place on bao bun.
                </p>
                        </div>

                        <div></div>
                    </div>
                </div>
            </div>
        </div>


        <article class="locator-callout">
            <div class="quick-form-container">


                <div class="product-locator-search">
                    <h2>Find Our Products</h2>
                    <form data-url="/locator/" action="/locator/" method="get">
                        <input Value="ANY_ANY" id="PROD" name="PROD" type="hidden" value="ANY_ANY" />
                        <div class="input-wrapper">
                            <input data-val="true" data-val-regex="Please enter a 5-digit ZIP code" data-val-regex-pattern="^\d{5}" data-val-required="Please enter a 5-digit ZIP code" id="ZIP" maxLength="5" name="ZIP" placeholder="Enter Zip" type="tel" defaultValue="" />
                            <input type="submit" value="Locate Bacon"></input>
                            <span class="field-validation-valid" data-valmsg-for="ZIP" data-valmsg-replace="true"></span>
                        </div>
                    </form>
                </div>

            </div>
        </article>
    </Layout>
)

export default RecipePage
